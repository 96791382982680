import React from 'react'
import {
    InfoContainer, InfoWrapper, Heading, Subtitle, MemberBox, 
    MemberImage, BoxHeading, BoxSubheading, MemberInfo, MembersDiv,
    ImageBox
} from './TeamElements';

const TeamSection = ({id, members}) => {
    return (
        <>
            <InfoContainer id = {id}>
                <InfoWrapper>
                    <Heading>Our Team</Heading>
                    <Subtitle>Meet the Founding PISOC Executive Team</Subtitle>
                    <MembersDiv>
                        {
                            members.map(m => (
                                <MemberBox>
                                    <ImageBox>
                                        <MemberImage src={m.imgSrc}/>   
                                    </ImageBox>
                                    
                                    <MemberInfo>
                                        <BoxHeading>{m.name}</BoxHeading>
                                        <BoxSubheading>{m.role}</BoxSubheading>
                                    </MemberInfo>  
                                </MemberBox>
                            ))
                        }
                    </MembersDiv>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default TeamSection;
