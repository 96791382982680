import React from "react";
import {
    Auth, API, graphqlOperation
} from 'aws-amplify'

import { retrieveBlogPosts } from "../../graphql/custom"
import { deleteBlogPost } from "../../graphql/mutations";

import { useHistory } from "react-router";
import { UpdateAuthorPage } from "./UpdateAuthorPage";
import { CreateBlogPage } from "./CreateBlogPage";
import { UpdateBlogPage } from "./UpdateBlogPage"

export default function AuthorEditPage() {
    const [blogList, setBlogList] = React.useState([])
    const [authorID, setAuthorID] = React.useState()
    const [updateAuthorModal, setUpdateAuthorModal] = React.useState(false)
    const [createBlogModal, setCreateBlogModal] = React.useState(false)
    const [updateBlogModal, setUpdateBlogModal] = React.useState(false)
    const [blogID, setBlogID] = React.useState()
    
    const history = useHistory();

    const openUpdateBlogModal = blogID => {
        setBlogID(blogID)
        setUpdateBlogModal(true)
    }


    React.useEffect(() => {
        Auth.currentSession()
            .then(res => {
                Auth.currentUserInfo().then(r => {
                    setAuthorID(r.attributes["custom:authorID"])
                })
            })
            .catch(e => {
                history.push("/cms/login")
            })
        API.graphql(graphqlOperation(retrieveBlogPosts))
            .then(v => {
                const blogposts = v.data.listBlogPosts.items
                const users = v.data.listUsers.items

                blogposts
                    .sort((a, b) => Date.parse(a.timeFormatted) - Date.parse(b.timeFormatted))
                    .reverse().map(el => {
                        el.author = users.filter(e => e.id === el.userID)[0]
                        let options = {
                            year: 'numeric', month: 'short', day: 'numeric',
                            hour: 'numeric', minute: 'numeric',
                            hour12: true,
                        };
                        el.timeFormatted = new Intl.DateTimeFormat('en-AU', options).format(new Date(el.timeFormatted))
                        return el
                    })
                    
                setBlogList(blogposts.filter(a => a._deleted !== true))
            });
    }, [history, createBlogModal, updateAuthorModal, updateBlogModal, blogID])

    const deletePost = (id, _version, cb) => {
        API.graphql(graphqlOperation(deleteBlogPost, {input: {id, _version}}))
            .then(r => {
                cb(id)
            })
            .catch(e => console.error(e));
    }

    return (
        <div>
            {
                blogList ? (
                    <div className="table-container container p-6" style={{ "minHeight": "70vh" }}>
                        <h1 className="title">
                            Blog Posts
                            <div className="buttons is-inline-flex is-pulled-right">
                                <button className="button is-primary" onClick={e => setUpdateAuthorModal(true)}>Update Author Details</button>
                                {
                                    authorID ? (<button className="button is-link" onClick={e => setCreateBlogModal(true)}>Create New Post</button>) : null
                                }
                                <SignOutButton />
                            </div>
                        </h1>
                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth is-size-5">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Author</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    blogList.map(el => (
                                        <tr key={el.id}>
                                            <td>{el.title}</td>
                                            <td>{el.author.name}</td>
                                            <td>{el.author.id === authorID ? (
                                                <button className="button is-warning is-small" onClick={() => openUpdateBlogModal(el.id)}>
                                                    <span>Edit</span>
                                                    <span className="icon is-small">
                                                        <i className="fas fa-edit"></i>
                                                    </span>
                                                </button>
                                            ) : ""}</td>
                                            <td>{el.author.id === authorID ? (
                                                <button className="button is-danger is-small" onClick={() => deletePost(el.id, el._version, setBlogID)}>
                                                    <span>Delete</span>
                                                    <span className="icon is-small">
                                                        <i className="fa-solid fa-xmark"></i>
                                                    </span>
                                                </button>
                                            ) : ""}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="container">
                        <p className="title">No Posts Available</p>
                    </div>
                )
            }
            <div className={`modal ${updateAuthorModal ? "is-active" : ""}`}>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <UpdateAuthorPage updateModal={updateAuthorModal} author={authorID} setAuthorID={setAuthorID} setUpdateModal={setUpdateAuthorModal} />
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={e => setUpdateAuthorModal(false)}></button>
            </div>
            <div className={`modal ${createBlogModal ? "is-active" : ""}`}>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <CreateBlogPage updateModal={createBlogModal} author={authorID} setCreateModal={setCreateBlogModal} />
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={e => setCreateBlogModal(false)}></button>
            </div>
            <div className={`modal ${updateBlogModal ? "is-active" : ""}`}>
                <div className="modal-background"></div>
                <div className="modal-content">
                    <UpdateBlogPage updateModal={updateBlogModal} author={authorID} id={blogID} setUpdateBlogModal={setUpdateBlogModal} />
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={e => setUpdateBlogModal(false)}></button>
            </div>
        </div>
    )
}



function SignOutButton() {
    const history = useHistory();

    const signOut = async () => {
        try {
            await Auth.signOut();
            history.push("/")
        } catch (error) {
            console.log('error signing in', error);
        }
    }

    return (
        <button className="button is-danger" onClick={signOut}>
            Sign Out
        </button>
    )

}