import img1 from '../../Images/astronaut.svg'
import img2 from '../../Images/banner.svg'

export const Info1 =  {
    id: '1',
    topLine: 'About Us',
    headline: 'Our Goal',
    description: 'The UNSW Personal Investment Society is a community designed to connect like minded long term investors and allow them to discuss investment strategies and recent financially/ economically relevant news. This will create a safe space for the next generation to learn to invest in the country for their own long term benefit. We will allow healthy discussion about riskier investments and trading, but will never support it as a smart long term investment strategy in any way, shape or form.',
    imgStart: false,
    imgSrc: img1,
    alt: 'astronaut',
}


export const Info2 =  {
    id: '2',
    topLine: 'About Us',
    headline: 'What We Believe',
    description: 'In a capitalist world, holding capital is one of the most important skills for anyone to have in order to secure their financial future. Since there are many ways to hold capital, we want to help everyone in UNSW understand this complicated world and help them expand their wealth and develop good monetary habits.',
    imgStart: true,
    imgSrc: img2,
    alt: 'lady looking at banners',
}

export const TeamInfo = {
    id: '3',
    members: [
        {
            name: "Tony Wu",
            role: "President",
            imgSrc: "/images/members/tony.jpg",
            imgAlt: "A picture of Yash Agasti",
        },
        {
            name: "Saurav Yash Agasti",
            role: "Vice President (Internal Affairs)",
            imgSrc: "/images/members/yash.jpg",
            imgAlt: "A picture of Yash Agasti",
        },
        {
            name: "Ivan Elhen",
            role: "Treasurer",
            imgSrc: "/images/members/ivan.jpg",
            imgAlt: "A picture of Yash Agasti",
        },
        {
            name: "Jeremy Aggio",
            role: "Vice President (External Affairs)",
            imgSrc: "/images/members/jeremy.jpg",
            imgAlt: "A picture of Yash Agasti",
        },
        {
            name: "Vincent Villafuerte",
            role: "Arc Delegate",
            imgSrc: "/images/members/vincent.jpg",
            imgAlt: "A picture of Yash Agasti",
        },
        {
            name: "George Cuorro",
            role: "Secretary",
            imgSrc: "/images/members/george.jpg",
            imgAlt: "A picture of Yash Agasti",
        }
    ]
}


