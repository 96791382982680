import React from 'react';
import "./HeroSection.css"
import angle from '../../Images/down_angle.svg'

function HeroSection() {
    return (
        <>
            <div className="hero-container">
                <div style={{position: "absolute",textAlign: "center", color: "black"}}>
                    <div className="text-container">
                        <h1>Welcome to the UNSW Personal Investment Society</h1>
                        <p>By Investors for Investors</p>
                        <a 
                            href="https://www.facebook.com/groups/544440489540504" 
                            target="_blank" 
                            className = "hero-btns"
                            rel="noreferrer"
                        >JOIN US</a>
                    </div>
                </div>
            </div>
            <img id="down-angle" src={angle} alt="More Info Underneath" /> 
        </>
    )
}

export default HeroSection;
