import styled from 'styled-components'

export const SponsorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background: white;
    padding: 4rem 0;
`

export const SponsorWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;
    margin-bottom: 20px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }
`

export const SponsorCard = styled.div`
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    // max-height: 340px;
    padding: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    transition: all 0.2s ease-in-out;
    &:hover {
        transform: scale(1.02);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
    }
`

export const SponsorIcon = styled.img`
    height: 160px;
    width: 160px;
    margin-bottom: 10px;
    border-radius: 100%;
    border: 2px solid white;
    background: #01bf71;

`

export const SponsorH1 = styled.h1`
    font-size: 2.5rem;
    color: black;
    margin-bottom: 48px;
    @media screen and (max-width: 480px) {
        font-size: 2rem;
    }
`
export const SponsorH2 = styled.h2`
    font-size: 1rem;
    margin-bottom: 10px;
`

export const SponsorP = styled.p`
    font-size: 1rem;
    text-align: center;
`
