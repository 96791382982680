import React from 'react';

import "./Hero.css";


const Hero = (props) => {
    return (
        <section className="hero is-medium is-link">
            <div className="hero-body">
                <p className="title">{props.title}</p>
                <p className="subtitle">{props.subtitle}</p>
            </div>
        </section>
    )
}

export default Hero;