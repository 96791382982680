import React from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Navbar from "./Components/Navbar"
import Footer from "./Components/Footer"

import HomePage from "./Pages/Home"
import JoinUsPage from "./Pages/JoinUs"
import AboutPage from "./Pages/About"
import BlogPage from "./Pages/Blog"
import BlogTemplate from "./Pages/BlogTemplate"
import AuthorBlogTemplate from './Pages/Blog/AuthorBlog'
import Page404 from "./Pages/404"
import {ChangePwdPage, SignInForm} from "./Pages/CMS"
import AuthorEditPage from './Pages/CMS/AuthorEditPage'

import "./bulma.min.css"
import './App.css'
import { Amplify } from 'aws-amplify'
import awsconfig from "./aws-exports"
import "@fortawesome/fontawesome-free/css/all.css"

Amplify.configure(awsconfig);


function App() {
  return (
    <> 
      <Router>
        <Navbar/>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/join-us" component={JoinUsPage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/blog/article/:id" component={BlogTemplate} />
          <Route path="/blog/author/:id" component={AuthorBlogTemplate} />
          <Route path="/blog" component={BlogPage}  />
          <Route path="/cms/login" component={SignInForm} />
          <Route path="/cms/reset-pwd" component={ChangePwdPage} />
          <Route path="/cms" component={AuthorEditPage} />
          <Route path="*" component={Page404} /> 
        </Switch>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
