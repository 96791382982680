import React from 'react'
import {InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, ImgWrap, Img, InfoButton} from './InfoElements';

const InfoSection1 = ({id, topLine, headline, description, imgStart, imgSrc, alt, buttonText, buttonLink, buttonColour}) => {
    return (
        <>
            <InfoContainer id = {id}>
                <InfoWrapper>
                    <InfoRow imgStart = {imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading>{headline}</Heading>
                                <Subtitle>
                                    {description}
                                    <br/>
                                    {
                                        buttonText ? (<InfoButton 
                                            colour={buttonColour}
                                            href={buttonLink} 
                                            target="_blank" 
                                            className = "hero-btns"
                                            rel="noreferrer"
                                        >{buttonText}</InfoButton>) : ""
                                    }
                                </Subtitle>
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <Img src={imgSrc} alt={alt}/>
                            <ImgWrap/>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection1;
