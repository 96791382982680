import React, {useEffect} from "react";

import HeroSection from "../../Components/HeroSection";
import InfoSection from "../../Components/InfoSection";
import { Info1, Info2 } from "./Data"
import Sponsor from "../../Components/Sponsor";

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <HeroSection/>
      <InfoSection {...Info1} /> 
      <InfoSection {...Info2} />
      <Sponsor/> 
    </>
  );
}