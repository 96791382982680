import "./404.css"

export default function Error404Page() {
  return (
    <>
      <div className="error404 hero-container">
			  <div style={{position: "absolute",textAlign: "center", color: "black", backdropFilter: "blur(2px)"}}>
          <div className="text-container">
            <h1>Error 404: Page Not Found!</h1>
            <p>Sorry! The page you are looking for isn't available.</p>
			    </div>
        </div>
      </div>
    </>
  );
}