import img1 from '../../Images/savings.svg'
import img2 from '../../Images/news.svg'
import img3 from '../../Images/master_plan.svg'
import img4 from '../../Images/messaging.svg'

export const JoinBecomeMember =  {
    id: '1',
    topLine: 'Join Us',
    headline: 'Become A Member',
    description: 'You can sign up on the SpArc page here, or sign up here if you have issues with the sparc website.',
    imgStart: false,
    imgSrc: img1,
    alt: 'Savings Account',
    buttonText: "SpArc",
    buttonLink: "https://member.arc.unsw.edu.au/s/clubdetail?clubid=0016F00003yNEEZQA4",
    buttonColour: "rgb(122, 193, 67)"
}

export const JoinFBGroup =  {
    id: '1',
    topLine: 'Join Us',
    headline: 'Join The Community',
    description: 'We have a Facebook Group where we facilitate discussion about helpful insights int investing as well as the latest news.',
    imgStart: true,
    imgSrc: img2,
    alt: 'FB news',
    buttonText: "Facebook Group",
    buttonLink: "https://www.facebook.com/groups/544440489540504",
    buttonColour: "#4267b2"
}

export const JoinFBPage =  {
    id: '1',
    topLine: 'Join Us',
    headline: 'Stay Updated',
    description: "If you like our Facebook Page, you won't miss any future events we'll host.",
    imgStart: false,
    imgSrc: img3,
    alt: 'master plan',
    buttonText: "Facebook Page",
    buttonLink: "https://www.facebook.com/UNSWPISoc",
    buttonColour: "#4267b2"
}

export const JoinDiscord =  {
    id: '1',
    topLine: 'Join Us',
    headline: 'The Real Community',
    description: "If you hate Facebook or don't have it, we're also on Discord!",
    imgStart: true,
    imgSrc: img4,
    alt: 'messaging',
    buttonText: "Discord",
    buttonLink: "https://discord.gg/aHatYx6",
    buttonColour: "#7289da"
}

