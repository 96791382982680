import React from "react";
import {
    Storage, API, graphqlOperation
} from 'aws-amplify'

import { updateBlogPost } from "../../graphql/mutations"

import { retrieveBlogPosts } from "../../graphql/custom"

import awsconfig from "../../aws-exports"

export function UpdateBlogPage(props) {
    const [title, setTitle] = React.useState("")
    const [preview, setPreview] = React.useState("")
    const [content, setContent] = React.useState("")
    const [dateCreated, setDateCreated] = React.useState("")
    const [file, setFile] = React.useState();
    const [img, setImg] = React.useState("/img");
    const [previewImg, setPreviewImg] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    const [version, setVersion] = React.useState(false);

    React.useEffect(() => {
        API.graphql(graphqlOperation(retrieveBlogPosts))
            .then(res => {
                const blogpost = res.data.listBlogPosts.items.filter(e => e.id === props.id)[0]
                if (blogpost) {
                    setTitle(blogpost.title || "")
                    setPreview(blogpost.preview || "")
                    setContent(blogpost.content || "")
                    setDateCreated(blogpost.timeFormatted.substr(0, 16) || "")
                    setPreviewImg(blogpost.img || "")
                    setImg(blogpost.img || "")
                    setVersion(blogpost._version)
                }
            })
            .catch(e => {
                console.error(e)
            })
    }, [props.id])

    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const vars = {
                input: {
                    id: props.id,
                    title,
                    img,
                    timeFormatted: new Date(dateCreated).toISOString(),
                    preview,
                    content,
                    userID: props.author,
                    _version: version
                }
            }
            if (file) {
                await new Promise(async (myResolve, myReject) => {
                    const storageData = await Storage.put(file.name.replace(' ', '').replace('%', ''), file, {
                        level: 'public',
                        type: file.type,
                        resumable: true,
                        errorCallback: (err) => {
                            console.error('Unexpected error while uploading', err);
                            myReject(err)
                        }
                    })
                    let url = `https://${awsconfig["aws_user_files_s3_bucket"]}.s3.${awsconfig["aws_user_files_s3_bucket_region"]}.amazonaws.com/public/${storageData.params.Key}`
                    console.log(storageData);
                    vars.input.img = url
                    setImg(url)
                    myResolve(storageData)
                })
            }
            

            console.log(vars.input);

            API.graphql(graphqlOperation(updateBlogPost, vars))
                .then(async r => {
                    console.log("success", r)
                    setLoading(false)
                    props.setUpdateBlogModal(false)
                }).catch(e => {
                    console.error(2, e)
                })

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="container p-6 has-background-white">
            <h1 className='title'>New Blog Post</h1>
            <form onSubmit={submit}>
                <fieldset>
                    <div className="field ">
                        <label className="label">Title</label>
                        <div className="control">
                            <input className="input" type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="e.g. Investing Basics" />
                        </div>
                    </div>


                    <div className="field ">
                        <label className="label">Date & Time Created</label>
                        <div className="control">
                            <input className="input" value={dateCreated} onChange={e => setDateCreated(e.target.value)} type="datetime-local" placeholder="e.g. Investing Basics" />
                        </div>
                    </div>
                    <div className="field ">
                        <label className="label">Image</label>
                        <div className="file has-name is-fullwidth">
                            <label className="file-label">
                                <input className="file-input" accept="image/png, image/jpeg" type="file" onChange={(e) => {
                                    setFile(e.target.files[0])
                                    let reader = new FileReader();
                                    reader.onload = () => {
                                        setPreviewImg(reader.result)
                                    }
                                    reader.readAsDataURL(e.target.files[0]);
                                }} />
                                <span className="file-cta">
                                    <span className="file-icon">
                                        <i className="fas fa-upload"></i>
                                    </span>
                                    <span className="file-label">
                                        Choose a file…
                                    </span>
                                </span>
                                <span className="file-name">
                                    {file ? file.name : "No file selected..."}
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="field">
                        <img className="image" style={{ width: "50%", height: "auto", display: !previewImg ? "none" : "" }} alt="author" src={previewImg} />
                    </div>


                    <div className="field">
                        <label className="label">Preview</label>
                        <div className="control">
                            <input className="input" type="text" value={preview} onChange={e => setPreview(e.target.value)} placeholder="e.g. This article will be super interesting because..." />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Content</label>
                        <div className="control">
                            <textarea className="textarea" value={content} onChange={e => setContent(e.target.value)} placeholder="What are we talking about today?"></textarea>
                        </div>
                    </div>

                    <div className="field is-grouped is-grouped-right">
                        <div className="control">
                            <button className="button is-link">

                                {
                                    isLoading ? (
                                        <i className="fas fa-cog fa-spin"></i>
                                    ) : "Submit"
                                }
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}
