import styled from 'styled-components';

export const InfoContainer = styled.div `
    padding: 4rem 0;
    margin-top: 20px;
    color: black;
    background:white;
    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`

export const InfoWrapper = styled.div`
    display: grid;
    /* height: 860px; */
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const Heading = styled.h1 ` 
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    color: black;
    text-align: center;
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
    font-family: apparat, sans-serif;
    font-weight: 500;
    font-style: normal; 
`

export const Subtitle = styled.p`
    
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: black;
    font-family: apparat-light, sans-serif;
    font-weight: 400;
    font-style: normal;
`

export const MemberBox = styled.div`
    display: block;
    // height: 36rem;
    width: 24rem;
    border-radius: 24px;
    padding: 2rem;
`

export const MemberImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;

`

export const BoxHeading = styled.h3`
    font-weight: 800;
    font-size: 24px;
    text-align:center;

`


export const BoxSubheading = styled.h4`
    font-weight: 300;
    font-size: 16px;
    text-align:center;
    font-family: apparat-light, sans-serif;
    font-weight: 400;
    font-style: italic;
`

export const MemberInfo = styled.div`
    padding: 1rem;

`
export const MembersDiv = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
`

export const ImageBox = styled.div`
    width: 100%;
    height: 20rem;
`