export const retrieveBlogPosts = `
query ListBlogPosts{
    listBlogPosts {
      items {
        id
        title
        img
        preview
        timeFormatted
        content
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    listUsers {
        items {
          id
          name
          img
          about
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
    }
  }
`