import React from 'react';
import Icon1 from "../../Images/polygon.io.png";

import "./Sponsor.css";
import {SponsorContainer, SponsorH1, SponsorWrapper, SponsorCard, SponsorIcon, 
    // SponsorH2, 
    SponsorP} from './SponsorElements';

const Sponsor = () => {
    return (
        <SponsorContainer id = "Sponsor">
            <SponsorH1>Meet the Sponsor</SponsorH1>
            <SponsorWrapper>
                <SponsorCard>
                    <SponsorIcon src={Icon1}/>
                    <SponsorP>Polygon supplies companies & developers with market data for stocks, currencies, forex, and crypto.</SponsorP>
                    <a 
                        href="https://www.Polygon.io" 
                        target="_blank" 
                        className="hero-btns"
                        rel="noreferrer"
                    >Polygon.io</a>
                </SponsorCard>
            </SponsorWrapper>
        </SponsorContainer>
    )
}
export default Sponsor;