import { Link } from "react-router-dom";
import "./blog.css";

function Card(props) {
    return (
        <div className="column p-6  is-full-mobile is-half-tablet is-one-third-fullhd">
            <div className="card">
                <div className="card-image">
                    <figure className="image is-4by3">
                        <img src={props.blogPostImg} alt={props.title} />
                    </figure>
                </div>
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img src={props.authorImg} alt={`${props.authorName}'s`} />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4">
                                <Link to={`/blog/article/${props.id}`}>{props.title}</Link>
                            </p>
                            <p className="subtitle is-6">
                                <Link to={`/blog/author/${props.authorID}`}>{props.authorName}</Link>
                            </p>
                        </div>
                    </div>
                    <div className="content">
                        <Link to={`/blog/article/${props.id}`}>{props.content}</Link>
                        <br /><br />
                        <time>{props.timeFormatted}</time>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function BlogPageList(props) {
    const truncate = (str, n) => {
        return (str.length > n) ? str.substr(0, n-1) + '…' : str;
    }
    
    return (
        <>
             <section className="text-gray-600 body-font" >
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col">
                        <div className="h-1 bg-gray-200 rounded overflow-hidden">
                            <div className="w-24 h-full bg-indigo-500"></div>
                        </div>
                    </div>
                    <div className="columns is-justify-content-center" id="bloglist">
                        {
                            props.blogList ? props.blogList.map(a => (
                                <Card
                                    key={a.id}
                                    id={a.id}
                                    title={truncate(a.title, 25)} 
                                    authorName={a.author.name} 
                                    authorImg={a.author.img}
                                    authorID={a.author.id}
                                    timeFormatted={a.timeFormatted} 
                                    blogPostImg={a.img} 
                                    // content={a.content}
                                    content={truncate(a.preview, 190)}
                                />
                            )) : null
                        }  
                    </div>
                </div>
            </section>
        </>
    )
}