import React from 'react'

// import { Link } from 'react-router-dom'
import {
    FooterContainer,
    FooterCopyright,
    FooterSocials,
    SocialButton,
    Footer,
    ArcLogoContainer,
    ArcLogo
} from './FooterElements'


import { faFacebookSquare, faDiscord } from '@fortawesome/free-brands-svg-icons'

import "./Footer.css"
                       
const FooterComponent = () => {
    return (
        <Footer>
            <FooterContainer>
                <FooterSocials>
                    <a 
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/UNSWPISoc"
                    >
                        <SocialButton icon={faFacebookSquare} size="3x" />
                    </a>
                    <a 
                        target="_blank"
                        rel="noreferrer" 
                        href="https://discord.gg/aHatYx6"
                    >
                        <SocialButton icon={faDiscord} size="3x" />
                    </a>
                </FooterSocials>
                <ArcLogoContainer>
                    <ArcLogo src="/images/Arc-white.png" />
                </ArcLogoContainer>
                <FooterCopyright>UNSW PERSONAL INVESTMENT SOCIETY © {new Date().getFullYear()}</FooterCopyright>
            </FooterContainer>
        </Footer>
    )
}

export default FooterComponent