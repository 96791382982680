import React, {useEffect} from "react";

import {
    useParams,
    Redirect
  } from "react-router-dom";

import Hero from "../../Components/Hero";
import BlogPageList from "../../Components/BlogList";
import InfoSection from "../../Components/InfoSection";
import { Amplify, graphqlOperation, API } from 'aws-amplify';
import awsconfig from '../../aws-exports';

import {retrieveBlogPosts} from '../../graphql/custom';

Amplify.configure(awsconfig);

export default function AuthorBlogPage() {
    let { id } = useParams();

    const [blogList, setBlogList] = React.useState([])
    

    React.useEffect(() => {
        API.graphql(graphqlOperation(retrieveBlogPosts))
        .then(v => {
            console.log("hi", v)
            const blogposts = v.data.listBlogPosts.items
            const users = v.data.listUsers.items

            blogposts
                .sort((a,b)=>Date.parse(a.timeFormatted)-Date.parse(b.timeFormatted))
                .reverse().map(el => {
                el.author = users.filter(e => e.id === el.userID)[0]
                let options = {
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: 'numeric', minute: 'numeric',
                    hour12: true,
                };
                el.timeFormatted = new Intl.DateTimeFormat('en-AU', options).format(new Date(el.timeFormatted))
                return el
            })
            setBlogList(blogposts.filter(e => id === e.author.id))
            if (!blogposts[0]) Redirect("/404")
        });
    }, [id])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Info1 = blogList[0] ? {
        id: '1',
        topLine: 'Author',
        headline: blogList[0].author.name,
        description: blogList[0].author.about,
        imgStart: false,
        imgSrc: blogList[0].author.img,
        alt: 'author image',
    } : {}
    
    return blogList[0] ? (
        <>
            <InfoSection {...Info1} />
            <Hero title="My Articles" subtitle="" />
            <BlogPageList blogList={blogList} />
        </>
    ) : <>
    </>
}

    


