import React,  {useState, useEffect} from 'react'
import { FaBars, FaTimes} from "react-icons/fa"
import { IconContext } from "react-icons/lib"
import {Nav, NavbarContainer, NavLogo, NavIcon, MobileIcon, NavMenu, NavTitle, NavLinks,
    Modal, ModalBackground, ModalContent, LinkBox, LinkButton} from "./NavbarElements"
import "./Navbar.css";

const Navbar = () => {
    const [click, setClick] = useState(false)
    const [scroll, setScroll] = useState(false)
    const [isModalActive, setModalActive] = useState(false)

    const toggleModalActive = () => {
        setModalActive(!isModalActive)
    }

    const handleClick = () => setClick(!click);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    }
    useEffect(()=> {
        changeNav()
        window.addEventListener("scroll", changeNav)
    },[])
    return (
        <>
            <IconContext.Provider value={{ color: "black" }}>
                <Nav active={scroll} click={click}>
                    <NavbarContainer>
                        <NavIcon src="/pisoc-logo.png"/>
                        <NavLogo to="/">
                            <NavTitle>UNSW PISoc</NavTitle>
                        </NavLogo>
                        <MobileIcon onClick = {handleClick}>
                            {click ? <FaTimes/> : <FaBars/>}
                        </MobileIcon>
                        <NavMenu onClick={handleClick} click ={click}>
                            <NavLinks to="/blog">BLOG</NavLinks>
                            <NavLinks to="/about">ABOUT</NavLinks>
                            <NavLinks to="/join-us">JOIN US</NavLinks>
                        </NavMenu>
                    </NavbarContainer>
                    <Modal className={!isModalActive ? `display-none` : ``}>
                        <ModalBackground className={!isModalActive ? `display-none` : ``} onClick={toggleModalActive}/>
                        <ModalContent className={!isModalActive ? `display-none` : ``}>
                            <LinkBox>
                                <LinkButton 
                                    style={{backgroundColor: 'rgb(243 239 250)', color: 'rgb(88 56 183)'}}
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSctccgpDCca3acwdkWSW9Ibf88mmQ2Fw0saLSsPxQJnz_aacA/viewform?usp=sf_link" 
                                    target="_blank"
                                    rel="noreferrer">Membership Form</LinkButton>
                                <LinkButton 
                                    style={{backgroundColor: '#ebfffc', color: '#00947e'}}
                                    href="https://www.facebook.com/UNSWPISoc" 
                                    target="_blank"
                                    rel="noreferrer"
                                >Facebook Page</LinkButton>
                                <LinkButton 
                                    style={{backgroundColor: 'rgb(239 246 250)', color: 'rgb(37 62 121)'}}
                                    href="https://www.facebook.com/groups/544440489540504" 
                                    target="_blank"
                                    rel="noreferrer">Facebook Group</LinkButton>
                                <LinkButton 
                                    style={{backgroundColor: 'rgb(243 239 250)', color: 'rgb(88 56 183)'}}
                                    href="https://discord.gg/adTDjFNY" 
                                    target="_blank"
                                    rel="noreferrer">Discord Server</LinkButton>
                            </LinkBox>
                        </ModalContent>
                        
                    </Modal>
                </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar;