import React from "react";
import { v4 as uuid } from 'uuid';
import {
    Auth,
    Storage, API, graphqlOperation
} from 'aws-amplify'

import { updateUser, createUser } from "../../graphql/mutations"

import { retrieveBlogPosts } from "../../graphql/custom"

import awsconfig from "../../aws-exports"

export function UpdateAuthorPage(props) {
    const [name, setName] = React.useState("")
    const [about, setAbout] = React.useState("")
    const [file, setFile] = React.useState();
    const [img, setImg] = React.useState("/img");
    const [previewImg, setPreviewImg] = React.useState("/preview");
    const [isLoading, setLoading] = React.useState(false);
    const [version, setVersion] = React.useState(1);
    const [formError, setFormError] = React.useState(false)

    React.useEffect(() => {
        API.graphql(graphqlOperation(retrieveBlogPosts))
            .then(res => {
                const user = res.data.listUsers.items.filter(e => e.id === props.author)[0]
                if (user) {
                    setName(user.name)
                    setAbout(user.about)
                    setPreviewImg(user.img)
                    setImg(user.img)
                    setVersion(user._version)
                    console.log("user", user);
                }
            })
            .catch(e => {
                console.error(e)
            })
    }, [props.author])

    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const vars = {
                input: {
                    name,
                    img,
                    about,
                    _version: version
                }
            }
            let url = "";

            if (file) {
                await new Promise(async (myResolve, myReject) => {
                    const storageData = await Storage.put(encodeURI(file.name), file, {
                        level: 'public',
                        type: file.type,
                        resumable: true,
                        errorCallback: (err) => {
                            console.error('Unexpected error while uploading', err);
                            setFormError(2)
                            setLoading(false)
                            myReject(err)
                        }
                    })
                    url = `https://${awsconfig["aws_user_files_s3_bucket"]}.s3.${awsconfig["aws_user_files_s3_bucket_region"]}.amazonaws.com/public/${storageData.params.Key}`
                    console.log(storageData);
                    setImg(url)
                    myResolve(storageData)
                })
                vars.input.img = url
            }


            if (props.author) {
                vars.input.id = props.author
                console.log(vars.input);
                API.graphql({ query: updateUser, variables: vars })
                    .then(r => {
                        console.log("success", r)
                        setLoading(false)
                        props.setUpdateModal(false)
                    }).catch(e => {
                        setFormError(0)
                        setLoading(false)
                        console.error(1, e)
                    })
            } else {
                vars.input.id = uuid()
                API.graphql(graphqlOperation(createUser, vars))
                    .then(async r => {
                        console.log("success", r)
                        const user = await Auth.currentAuthenticatedUser();
                        await Auth.updateUserAttributes(user, {
                            'custom:authorID': r.data.createUser.id
                        })
                        props.setAuthorID(r.data.createUser.id)
                        setLoading(false)
                        props.setUpdateModal(false)
                    }).catch(e => {
                        setFormError(0)
                        setLoading(false)
                        console.error(2, e, vars)
                    })
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className="container p-6 has-background-white">
            <h1 className='title'>Author Details</h1>
            <form onSubmit={submit}>
                <div className="field">
                    <p className="control has-icons-left has-icons-right">
                        <input className={formError === 0 ? "input is-danger" : "input  "} value={name} type="text" placeholder="Name" onChange={e => setName(e.target.value)} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                </div>
                <div className="field">
                    <p className="control has-icons-left has-icons-right">
                        <textarea className={formError === 1 ? "textarea is-danger" : "textarea "} value={about} type="text" placeholder="About You" onChange={e => setAbout(e.target.value)} />
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                </div>
                <div className="field">
                    <div className="file has-name is-fullwidth">
                        <label className="file-label">
                            <input className={formError === 2 ? "file-input is-danger" : "file-input "} accept="image/png, image/jpeg" type="file" onChange={(e) => {
                                setFile(e.target.files[0])
                                let reader = new FileReader();
                                reader.onload = () => {
                                    setPreviewImg(reader.result)
                                }
                                reader.readAsDataURL(e.target.files[0]);
                            }} />
                            <span className="file-cta">
                                <span className="file-icon">
                                    <i className="fas fa-upload"></i>
                                </span>
                                <span className="file-label">
                                    Choose a file…
                                </span>
                            </span>
                            <span className="file-name">
                                {file ? file.name : "No file selected..."}
                            </span>
                            
                        </label>
                    </div>

                </div>
                <div className="field">
                    <img className="image" style={{ width: "50%", height: "auto", display: !previewImg ? "none" : "" }} alt="author" src={previewImg} />
                </div>

                <div className="field is-grouped is-grouped-right">
                    <div className="control">
                        <button className="button is-link">

                            {
                                isLoading ? (
                                    <i className="fas fa-cog fa-spin"></i>
                                ) : "Submit"
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}