import React, { useState } from 'react'
import {
    Auth,
} from 'aws-amplify'

import { useHistory } from 'react-router-dom';

export function SignInForm() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [code, setCode] = useState("")
    const [newpassword, setNewPassword] = useState("")
    const [confirmPwd, setConfirmPwd] = useState("")
    const [resetErrorMsg, setResetErrorMsg] = useState("")

    const [formState, setFormState] = useState(0)

    const history = useHistory()

    const signIn = async () => {
        try {
            const user = await Auth.signIn(username, password);
            console.log(user);
            if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                setFormState(2)

                console.log("reset-pwd");
            }
            else if (Auth.currentSession()) history.push("/cms")
            else throw new Error(user)
        } catch (error) {
            console.log('error signing in', error);
            setFormState(error.message)
            if (error.message === "Password reset required for the user") {
                setFormState(3)
            }
        }
    }

    const completePwd = async () => {
        const user = await Auth.signIn(username, password);
        Auth.completeNewPassword(
            user, // the Cognito User Object
            newpassword, // the new password
        )
            .then((user) => {
                // at this time the user is logged in if no MFA required
                console.log("logged in:", user);
                history.push("/cms")

            })
            .catch((e) => {
                console.error(e);
                setResetErrorMsg(e.message)
            });
    }

    const resetPwd = async () => {
        Auth.forgotPasswordSubmit(
            username, // the Cognito User Object
            code,
            newpassword, // the new password
        )
            .then((user) => {
                // at this time the user is logged in if no MFA required
                console.log("logged in:", user);
                history.push("/cms")

            })
            .catch((e) => {
                console.error(e);
                setResetErrorMsg(e.message)
            });
    }

    const sendResetCode = async () => {
        await Auth.forgotPassword(username)
        .then((data) => {console.log(data); setFormState(3)})
        .catch((err) => console.log(err));
        console.log("sent code");
    }

    return (
        <div className="container p-6">
            <h1 className='title'>Sign In</h1>
            <fieldset>
                <div className="field">
                    <p className="control has-icons-left has-icons-right">
                        <input className="input" value={username} type="text" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                </div>
                <div className="field">
                    <p className="control has-icons-left">
                        <input className="input" value={password} type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-lock"></i>
                        </span>
                    </p>
                </div>
                {
                    formState !== 0 ? (<span className="help is-danger">{formState}</span>) : (<> </>)
                }
                <div className="field">
                    <span className="help is-info is-clickable" onClick={sendResetCode}>Forgot Password?</span>
                </div>
                <div className="field">
                    <p className="control">
                        <button className="button is-success" onClick={signIn}>
                            Login
                        </button>
                    </p>
                </div>
            </fieldset>
            {
                formState === 2 || formState === 3 ? (
                    <div className="modal is-active ">
                        <div className="modal-background"></div>
                        <div className="modal-content has-background-light p-6">
                            <fieldset>
                                {
                                    formState === 3 ? (
                                        <div className="field">
                                            <p className="control has-icons-left has-icons-right">
                                                <input className="input" value={code} type="input" placeholder="Code In Email" onChange={e => setCode(e.target.value)} />
                                                <span className="icon is-small is-left">
                                                    <i className="fas fa-envelope"></i>
                                                </span>
                                                <span className="icon is-small is-right">
                                                    <i className="fas fa-check"></i>
                                                </span>
                                            </p>
                                        </div>
                                    ) : null
                                }
                                <div className="field">
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input" value={newpassword} type="password" placeholder="New Password" onChange={e => setNewPassword(e.target.value)} />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </p>
                                </div>
                                <div className="field">
                                    <p className="control has-icons-left has-icons-right">
                                        <input className="input" value={confirmPwd} type="password" placeholder="Confirm Password" onChange={e => setConfirmPwd(e.target.value)} />
                                        <span className="icon is-small is-left">
                                            <i className="fas fa-envelope"></i>
                                        </span>
                                        <span className="icon is-small is-right">
                                            <i className="fas fa-check"></i>
                                        </span>
                                    </p>
                                </div>
                                {
                                    resetErrorMsg !== "" ? (<span className="help is-danger">{resetErrorMsg}</span>) : (<> </>)
                                }
                                <div className="field">
                                    <p className="control">
                                        <button className="button is-success" onClick={formState === 2 ? completePwd : resetPwd}>
                                            Set New Password
                                        </button>
                                    </p>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                ) : null
            }
        </div>

    )
}





export function ChangePwdPage() {
    const [oldpassword, setOldPassword] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPwd, setConfirmPwd] = useState("")
    const [formState, setFormState] = useState(0)

    const ChangePwd = (e) => {
        try {
            if (password !== confirmPwd) throw new Error("Passwords do not match!")
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    Auth.changePassword(user, oldpassword, password)
                        .then(res => {
                            console.log(res)
                        })
                        .catch(e => {
                            console.error(e)
                            throw e
                        })
                }).catch(e => {
                    console.error(e)
                    throw e
                })

        } catch (error) {
            setFormState(error.message)
        }

    }
    return (
        <div className="container p-6">
            <h1 className='title'>Author Details</h1>
            <fieldset>
                <div className="field">
                    <p className="control has-icons-left has-icons-right">
                        <input className="input" value={oldpassword} type="password" placeholder="Current Password" onChange={e => setOldPassword(e.target.value)} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                </div>
                <div className="field">
                    <p className="control has-icons-left has-icons-right">
                        <input className="input" value={password} type="password" placeholder="New Password" onChange={e => setPassword(e.target.value)} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                </div>
                <div className="field">
                    <p className="control has-icons-left has-icons-right">
                        <input className="input" value={confirmPwd} type="password" placeholder="Confirm Password" onChange={e => setConfirmPwd(e.target.value)} />
                        <span className="icon is-small is-left">
                            <i className="fas fa-envelope"></i>
                        </span>
                        <span className="icon is-small is-right">
                            <i className="fas fa-check"></i>
                        </span>
                    </p>
                </div>
                {
                    formState !== 0 ? (<span className="help is-danger">{formState}</span>) : (<> </>)
                }
                <div className="field">
                    <p className="control">
                        <button className="button is-success" onClick={ChangePwd}>
                            Change Password
                        </button>
                    </p>
                </div>
            </fieldset>
        </div>
    )
}

export function CreateBlogPost() {
    return (
        <div className="container p-6">

        </div>
    );
}