import React from "react";
import { v4 as uuid } from 'uuid';
import {
    Storage, API, graphqlOperation
} from 'aws-amplify'

import { createBlogPost } from "../../graphql/mutations"

import awsconfig from "../../aws-exports"

export function CreateBlogPage(props) {
    const [title, setTitle] = React.useState("")
    const [preview, setPreview] = React.useState("")
    const [content, setContent] = React.useState("")
    const [dateCreated, setDateCreated] = React.useState("")
    const [file, setFile] = React.useState();
    const [img, setImg] = React.useState("/img");
    const [previewImg, setPreviewImg] = React.useState();
    const [isLoading, setLoading] = React.useState(false);
    const [formError, setFormError] = React.useState(false)

    const submit = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const vars = {
                input: {
                    id: uuid(),
                    title,
                    img,
                    timeFormatted: new Date(dateCreated).toISOString(),
                    preview,
                    content,
                    userID: props.author,
                }
            }
            let data = await new Promise(async (myResolve, myReject) => {
                if (!file) throw myReject("bad file") 
                let storageData  = await Storage.put(file.name.replace(' ', '').replace('%', ''), file, {
                    level: 'public',
                    type: file.type,
                    resumable: true,
                    errorCallback: (err) => {
                        console.error('Unexpected error while uploading', err);
                        myReject("Image Error")
                    }
                })
                
                
                let url = `https://${awsconfig["aws_user_files_s3_bucket"]}.s3.amazonaws.com/public/${storageData.params.Key}`
                console.log(storageData);
                vars.input.img = url
                setImg(url)
                myResolve(storageData)
            }).catch (e => {
                throw new Error("Image Error")
            })




            console.log(vars.input);

            API.graphql(graphqlOperation(createBlogPost, vars))
                .then(async r => {
                    console.log("success", r)
                    setLoading(false)
                    props.setCreateModal(false)
                }).catch(e => {
                    console.error(2, e)
                    setFormError(2)
                    setLoading(false)
                })

        } catch (error) {
            console.error(error.message);
            if (error.message === "Invalid time value"){
                setFormError(1)
                setLoading(false)
            }
            else if (error.message === "Image Error"){
                setFormError("Image Error")
                setLoading(false)
            }
            else {
                setFormError(0)
                setLoading(false)
            }
        }
    }

    return (
        <div className="container p-6 has-background-white">
            <h1 className='title'>New Blog Post</h1>
            <form onSubmit={submit}>
                <fieldset>
                    <div className="field ">
                        <label className="label">Title</label>
                        <div className="control">
                            <input required className={`input ${!title ? "is-danger" : ""}`} type="text" value={title} onChange={e => setTitle(e.target.value)} placeholder="e.g. Investing Basics" />
                        </div>
                    </div>


                    <div className={`field`}>
                        <label className="label">Date & Time Created</label>
                        <div className="control">
                            <input className={`input ${!dateCreated || formError === 2 ? "is-danger" : ""}`} value={dateCreated} onChange={e => setDateCreated(e.target.value)} type="datetime-local" placeholder="e.g. Investing Basics" />
                        </div>
                    </div>
                    <div className="field ">
                        <label className="label">Image</label>
                        <div className={`file has-name is-fullwidth ${formError === "Image Error" ? "is-danger" : ""}`}>
                            <label className="file-label">
                                <input className={`file-input`} accept="image/png, image/jpeg" type="file" onChange={(e) => {
                                    setFile(e.target.files[0])
                                    let reader = new FileReader();
                                    reader.onload = () => {
                                        setPreviewImg(reader.result)
                                    }
                                    reader.readAsDataURL(e.target.files[0]);
                                }} />
                                <span className="file-cta">
                                    <span className="file-icon">
                                        <i className="fas fa-upload"></i>
                                    </span>
                                    <span className="file-label">
                                        Choose a file…
                                    </span>
                                </span>
                                <span className="file-name">
                                    {file ? file.name : "No file selected..."}
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className="field">
                        <img className="" style={{height: "16rem",width: "16rem", display: !previewImg ? "none" : "inline" }} alt="author" src={previewImg} />
                    </div>


                    <div className="field">
                        <label className="label">Preview</label>
                        <div className="control">
                            <input className={`input ${!preview ? "is-danger" : ""}`} type="text" value={preview} onChange={e => setPreview(e.target.value)} placeholder="e.g. This article will be super interesting because..." required />
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">Content</label>
                        <div className="control">
                            <textarea required className={`textarea ${!content ? "is-danger" : ""}`} value={content} onChange={e => setContent(e.target.value)} placeholder="What are we talking about today?"></textarea>
                        </div>
                    </div>

                    <div className="field is-grouped is-grouped-right">
                        <div className="control">
                            <button className="button is-link">

                                {
                                    isLoading ? (
                                        <i className="fas fa-cog fa-spin"></i>
                                    ) : "Submit"
                                }
                            </button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    )
}
