import React, {useEffect} from "react";

import Hero from "../../Components/Hero";
import BlogPageList from "../../Components/BlogList";

import { Amplify, graphqlOperation, API } from 'aws-amplify';
import awsconfig from '../../aws-exports';

import {retrieveBlogPosts} from '../../graphql/custom';


Amplify.configure(awsconfig);

export default function BlogPage(props) {

    const [blogList, setBlogList] = React.useState([])
    

    React.useEffect(() => {
        API.graphql(graphqlOperation(retrieveBlogPosts))
        .then(v => {
            console.log("hi", v)
            const blogposts = v.data.listBlogPosts.items
            const users = v.data.listUsers.items

            blogposts
                .sort((a,b)=>Date.parse(a.timeFormatted)-Date.parse(b.timeFormatted))
                .reverse().map(el => {
                el.author = users.filter(e => e.id === el.userID)[0]
                let options = {
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: 'numeric', minute: 'numeric',
                    hour12: true,
                };
                el.timeFormatted = new Intl.DateTimeFormat('en-AU', options).format(new Date(el.timeFormatted))
                return el
            })
            setBlogList(blogposts.filter(a => !a._deleted))
        });
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <>
            <Hero title="The Investor's Blog" subtitle="The Financial Feed That Makes Sense" />
            <BlogPageList blogList={blogList} />
        </>
    )
}
