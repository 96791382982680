import img1 from '../../Images/phone_graph.svg'
import img2 from '../../Images/data_trends.svg'

export const Info1 =  {
    id: '1',
    topLine: 'Our Goal',
    headline: 'Investment',
    description: 'The UNSW Personal Investment Society bridges the divide between the esoteric discipline of investing and non-business students. Approximately 75% of our 700+ members are not apart of the business school, and yet, they still thrive in our community. This is because our focus is on investment skills that build upon experience in non-business discplines.',
    imgStart: false,
    imgSrc: img1,
    alt: 'Car',
}

export const Info2 =  {
    id: '2',
    topLine: 'Our Mission',
    headline: 'Student',
    description: 'Our mission is to instill responsible personal finance habits in students from all disciplines, especially non-business students. We plan to achieve this by creating a safe space for students curious about investment to bounce ideas off each other and explore the art of building long-term wealth together.',
    imgStart: true,
    imgSrc: img2,
    alt: 'Investment',
}
