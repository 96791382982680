import styled from "styled-components"
import { Link } from 'react-router-dom'


export const Nav = styled.nav`
  background: ${({ active }) =>
    active
      ? "white"
      : ""};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 1;
  @media screen and (max-width: 960px) {
    background: ${({ click }) => (click ? "white" : "")};
    transition: 0.8s all ease;
  }
`
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  z-index: 1;
  width: 100%;
  
  @media screen and (max-width: 370px) {
    width: 97%;
  }
  @media screen and (max-width: 960px) {
    padding-right: 5.5rem;
  }
  
  @media screen and (min-width: 960px) {
    max-width: 1040px;
    
  }
  @media screen and (min-width: 1300px) {
    max-width: 1200px;
  }
`

export const NavLogo = styled(Link)`
  color: black;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  font-size: 1.5rem;
  display: flex;
  @media screen and (max-width: 400px) {
    font-size: 1.2rem;
    justify-content: flex-start;
  }
  @media screen and (max-width: 384px) {
    max-width: 85%;
  }
  @media screen and (min-width: 960px) {
    flex:1;
    justify-content: flex-start;
    align-items: center;
  }
`
export const NavTitle = styled.div`
  text-align: left;
  padding: 0 1rem;
  font-family: apparat-light, sans-serif;
  font-weight: 600;
  font-style: normal;
`
export const NavIcon = styled.img`
  // padding: 0.75rem;
  // padding-top: 1rem;
  height: 48px;
  box-sizing: border-box;
  justify-self: start;
  @media screen and (max-width: 960px) {
    box-sizing: content-box;
    padding:0;
    padding: 0 0.75rem;
    // padding: 0.75rem;
    // height:100%;
  }
`


export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: ${({ click }) => (click ? "100%" : "-1000px")};
    opacity: 1;
    transition: all 0.2s ease;
    background: white;
  }
`
export const NavItem = styled.li`
  height: 80px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`

export const NavButton = styled.button`
  a {
    font-family: apparat, sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  padding: 0 16px;
`

export const NavLinks = styled(Link)`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-family: apparat, sans-serif;
  font-weight: 300;
  font-style: normal;
  padding: 0.5rem 3rem;
  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    overflow: hidden;
    &:hover {
      color: black;
      transition: all 0.3s ease;
    }
  }
`

export const Modal = styled.div`
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`

export const ModalBackground = styled.div`
  background-color: rgba(10,10,10,.86);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const ModalContent = styled.div`
  margin: 0 auto;
  overflow: auto;
  position: relative;
`

export const LinkBox = styled.div`
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  color: #4a4a4a;
  display: block;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
`

export const LinkButton = styled.a`
  background-color: #fff;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em- 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em- 1px);
  text-align: center;
  white-space: nowrap;
  user-select: none;
  position: relative;
  vertical-align: top;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.375em;
  box-shadow: none;
  display: inline-flex;
  font-size: 1rem;
  height: 2.5em;
  margin: 2rem;
`