import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Footer = styled.footer`
  color: white;
  background: black;
  padding: 5vw;
  width:100%;
  margin: auto;
`

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  height:100%;
  margin: auto;
  @media screen and (min-width: 960px) {
    flex-direction: row;
  }
  width: 100%;
  @media screen and (min-width: 960px) {
    max-width: 1040px;
  }
  @media screen and (min-width: 1300px) {
    max-width: 1200px;
  }
`

export const FooterCopyright = styled.div`
  font-size: 1rem;
  text-align: center;
  flex: 1;
  padding: 1rem;
  @media screen and (min-width: 960px) {
    text-align: right;
  }
`

export const FooterSocials = styled.div`
  padding: 1rem;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`

export const SocialButton = styled(FontAwesomeIcon)`
  margin: 1rem;
  color: #fff;
`

export const ArcLogo = styled.img`
  height: 6rem;
`

export const ArcLogoContainer = styled.div`
  padding: 1rem;
`