import React, {useEffect} from "react";

import InfoSection from "../../Components/InfoSection"

import { JoinBecomeMember, JoinFBGroup, JoinFBPage, JoinDiscord } from "./Data"

export default function JoinUsPage() {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    return (
      <>
        <InfoSection {...JoinBecomeMember} />
        <InfoSection {...JoinFBGroup} />
        <InfoSection {...JoinFBPage} />
        <InfoSection {...JoinDiscord} />
      </>
    )
}