import React, {useEffect, useState} from "react";
import {
    useParams,
    Link
  } from "react-router-dom";

import ReactMarkdown from "react-markdown";

import { graphqlOperation, API } from 'aws-amplify';


import {retrieveBlogPosts} from '../../graphql/custom';

export default function BlogTemplate() {
    const [blog, setBlog] = useState({
        empty: true
    })
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        API.graphql(graphqlOperation(retrieveBlogPosts))
        .then(v => {
            console.log("hi", v)
            const blogposts = v.data.listBlogPosts.items
            const users = v.data.listUsers.items

            blogposts.map(el => {
                el.author = users.filter(e => e.id === el.userID)[0]
                let options = {
                    year: 'numeric', month: 'short', day: 'numeric',
                    hour: 'numeric', minute: 'numeric',
                    hour12: true,
                };
                el.timeFormatted = new Intl.DateTimeFormat('en-AU', options).format(new Date(el.timeFormatted))
                return el
            })
            let currentBlog = blogposts.filter(e => e.id === id)[0]
            if (currentBlog === null) throw new Error("No Blog Exists")
            setBlog(currentBlog)
        })
        .catch(e => {
            console.error(e)
        });
    }, [id])

    return !blog.empty ? (
        <div className="container">
            <section className="articles">
                <div className="column is-8 is-offset-2">
                    <div className="card article">
                        <div className="card-image">
                            <figure className="image is-2by1">
                                <img src={blog.img}  alt="Placeholder"/>
                            </figure>
                        </div>
                        <div className="card-content">
                            <div className="media is-flex-direction-column ">
                                <div className="media-content has-text-centered" style={{width: "100%"}}>
                                    <p className="title article-title">{blog.title}</p>
                                    <p className="subtitle is-6 article-subtitle">
                                        <Link to={`/blog/author/${blog.author.id}`}>{blog.author.name}</Link> @ {blog.timeFormatted}
                                    </p>
                                </div>
                            </div>
                            <div className="content article-body">
                                <ReactMarkdown>{blog.content}</ReactMarkdown>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    ) : <></>
}