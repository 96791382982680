import React, {useEffect} from "react";

// import HeroSection from "../../Components/HeroSection";
import InfoSection from "../../Components/InfoSection";
import TeamSection from "../../Components/TeamSection";

import { Info1, Info2, TeamInfo } from "./Data"

export default function AboutPage() {
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <InfoSection {...Info1} />
      <InfoSection {...Info2} />
      <TeamSection {...TeamInfo} />
    </>
  );
}